<template>
        <v-card id="create">
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Contact Info</v-toolbar-title>
          </v-toolbar>
          <v-container fluid :class="{ 'p6': !altUser }">
            <v-row class="child-flex" >
              <v-col cols="12">
                <!-- Phone List -->
                <phone-list
                  v-if="user && user.phones"
                  :phonelist="user.phones"
                  ref="phoneList"
                  @verify="onVerify"
                >
                </phone-list>
                <v-divider inset></v-divider>
                <!-- Email List -->
                <email-list
                  v-if="user && user.emails"
                  :emailList="user.emails"
                  ref="emailList"
                  @verify="onVerify"
                >
                </email-list>
              </v-col>
            </v-row>
            <v-row dense v-if="!altUser">
              <v-col cols="12">
                <v-speed-dial
                  v-model="fab"
                  bottom
                  left
                  absolute
                  direction="right"
                  transition="slide-x-transition"
                >
                  <template v-slot:activator>
                    <v-btn
                      :color="'color3Text color3--text'"
                      :dark="fab"
                      fab
                      v-model="fab"
                    >
                      <v-icon v-if="fab">fas fa-times</v-icon>
                      <v-icon v-else>fas fa-plus</v-icon>
                    </v-btn>
                  </template>
                  <v-btn fab small @click="() => { addPhone() }" color="color3 color3Text--text">
                    <v-icon>fas fa-phone</v-icon>
                  </v-btn>
                  <v-btn fab small @click="() => { addEmail() }" color="color3 color3Text--text">
                    <v-icon>fas fa-envelope</v-icon>
                  </v-btn>
                  <v-btn small v-if="false">
                    <v-icon>fas fa-map-marker-alt</v-icon>
                    Add an Address
                  </v-btn>
                </v-speed-dial>

              </v-col>
            </v-row>
          </v-container>
          <v-dialog
            v-model="dialog"
            scrollable
            persistent
            max-width="500px"
            transition="dialog-transition"
            v-if="!altUser"
          >
            <v-card>
              <v-toolbar color="color1 color1Text--text">
                <v-toolbar-title>Add a contact</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="color2 color2Text--text"
                  small fab
                  @click.stop="dialog = false"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-expand-transition>
                  <contact-add
                    v-if="dialog"
                    :addType="addType"
                    :contactIn="contact"
                    @verified="dialog = false"
                  ></contact-add>
                </v-expand-transition>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
</template>

<script>
import PhoneList from '../Utils/PhoneList.vue'
import EmailList from '../Utils/EmailList.vue'
import ContactAdd from '@/components/Account/ContactAdd'

export default {
  props: ['altUser'],
  data () {
    return {
      fab: false,
      dialog: false,
      addType: null,
      contact: null
    }
  },
  computed: {
    user () {
      return this.altUser || this.$store.getters.user
    },
    fullname () {
      return this.user && [this.user.firstName, this.user.middleName, this.user.lastName].join(' ')
    }
  },
  components: {
    PhoneList,
    EmailList,
    ContactAdd
  },
  methods: {
    addPhone (a) {
      if (a) this.contact = a
      this.addType = 'Mobile Phone'
      this.dialog = true
    },
    addEmail (a) {
      if (a) this.contact = a
      this.addType = 'Email'
      this.dialog = true
    },
    onVerify (dto) {
      if (dto.type === 'email') {
        this.addEmail(dto.address)
      } else {
        this.addPhone(dto.number)
      }
    }
  }
}
</script>

<style scoped>
  #create .v-speed-dial {
    position: absolute;
  }

  #create .v-btn--floating {
    position: relative;
  }
  .p6 {
    padding-bottom: 64px;
  }
</style>
