<template>
    <v-dialog
      v-model="dialog"
      max-width="400"
      persistent
    >
      <v-card v-if="editableEmail">
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title v-if="mode === 'edit'">{{editableEmail.address}}</v-toolbar-title>
          <v-toolbar-title v-else>New Email</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click.native="cancel"
            color="color2Text--text"
            :disabled="saving || sending || deleting"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <loading-bar :value="loading"></loading-bar>
        <v-container>
          <v-row dense v-if="mode === 'add'">
            <v-col>
              <v-text-field
                label="Address"
                v-model="editableEmail.address"
                required
                :rules="[
                  () => $v.editableEmail.address.required || 'An email address is required',
                  () => $v.editableEmail.address.email || 'A valid email address is required'
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="mode === 'edit' && !editableEmail.isVerified">
            <v-col class="text-center" cols="12">
              <v-alert type="error" text :value="true">
                <strong>This email has not been verified</strong><br>
                You must verify the email before you can edit the settings
              </v-alert>
            </v-col>
            <v-col class="text-center" cols="12">
              <v-alert color="success white--text" v-model="sent" dismissible>
                <strong>A new verification link has been sent</strong>
              </v-alert>
            </v-col>
            <v-col class="text-center" cols="12" v-if="!sent">
              <v-btn
                color="color3 color3Text--text"
                :disabled="sending"
                :loading="sending"
                @click.native="resend"
              >
                Resend Verification Link
              </v-btn>
            </v-col>
          </v-row>
          <v-list two-line v-if="mode === 'edit' && editableEmail.isVerified">
            <v-list-item>
              <v-list-item-action>
                <v-checkbox color="color3" v-model="editableEmail.isPrimary" :disabled="!canEditPrimary"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Primary</v-list-item-title>
                <v-list-item-subtitle>Use this as your primary address</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="false">
              <v-list-item-action>
                <v-checkbox color="color3" v-model="editableEmail.isPublic" :disabled="!editableEmail.isVerified"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Public</v-list-item-title>
                <v-list-item-subtitle>Visible to the public</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="text-right"   v-if="!(mode === 'edit' && !editableEmail.isVerified)">
            <v-col cols="12">
              <v-btn
                v-if="email.id !== 0 && !email.isPrimary"
                fab
                color="error white-text"
                class="mr-3"
                :loading="deleting"
                :disabled="deleting"
                @click.native="deleteMe"
              >
                <v-icon>fas fa-trash</v-icon>
              </v-btn>
              <v-btn
                :disabled="!saveable || saving || sending || deleting"
                @click="save"
                :loading="saving"
                ripple
                color="success white--text"
                class="pl-0"
                fab
              >
                <v-icon>fas fa-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
</template>

<script>
import Email from '../../classes/Email'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import * as mutations from '../../store/MutationTypes'

export default {
  props: ['email', 'listCount'],
  mixins: [validationMixin],
  validations: {
    editableEmail: {
      address: { required, email }
    }
  },
  data () {
    return {
      dialog: false,
      editableEmail: null,
      saving: false,
      sending: false,
      sent: false,
      deleting: false
    }
  },
  computed: {
    saveable () {
      return !this.saving && this.mode === 'add' ? !this.$v.$invalid : this.dirty
    },
    dirty () {
      return JSON.stringify(this.email) !== JSON.stringify(this.editableEmail)
    },
    mode () {
      return this.editableEmail.id === 0 ? 'add' : 'edit'
    },
    canEditPrimary () {
      return this.listCount > 1 && this.editableEmail.isVerified
    },
    loading () {
      return this.saving || this.sending || this.deleteing
    }
  },
  methods: {
    cancel () {
      this.dialog = false
      this.$v.$reset()
      this.$emit('cancel')
    },
    save () {
      if (!this.saveable) return
      this.saving = true
      this.$VBL.user.postEmail(this.editableEmail)
        .then((response) => {
          this.$store.commit(mutations.SET_USER, response.data)
          this.saving = false
          this.dialog = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deleteMe () {
      if (this.email.id === 0) {
        this.cancel()
        return
      }
      this.deleting = true
      this.$VBL.user.deleteEmail(this.email.id)
        .then((response) => {
          this.$store.commit(mutations.SET_USER, response.data)
          this.deleting = false
          this.dialog = false
        })
        .catch((error) => {
          console.log(error)
          this.deleting = false
        })
    },
    resend () {
      this.sending = true
      this.$VBL.user.resendEmailConfirm(this.email.id)
        .then(() => {
          this.sent = true
          this.sending = false
        })
        .catch((error) => {
          console.log(error.response)
          this.sending = false
        })
    }
  },
  watch: {
    email (newVal, oldVal) {
      if (newVal) {
        this.editableEmail = new Email(newVal)
        this.dialog = true
        this.$v.editableEmail.address.$reset()
      }
    }
  }
}
</script>

<style>

</style>
