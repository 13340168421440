<template>
  <v-list two-line>
    <v-list-item v-for="(phone,i) in list" :key="i">
      <v-list-item-action>
        <v-icon color="color1" v-if="i === 0">
          fas fa-phone
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{phone.number | usPhone }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{phone.propsString}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <opt-in :phone="phone.number"></opt-in>
      </v-list-item-action>
      <v-list-item-action @click="selectPhone(phone)" v-if="!phone.isVerified">
        <v-btn small text icon ripple color="color3">
          <v-icon>fas fa-cog</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action @click="deletePhone(phone)">
        <v-btn small text icon ripple color="error">
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="phonelist.length == 0">
      <v-list-item-action>
        <v-icon color="color1">
          fas fa-phone
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          No phone number on file
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <phone-editor :phone="!dialog && selectedPhone" :listCount="phonelist.length" @cancel="onCancel"></phone-editor>
    <vbl-confirm-dialog
      :ask="dialog"
      @confirm="doDelete"
      @deny="onCancel"
      :loading="deleting"
    ></vbl-confirm-dialog>

  </v-list>
</template>

<script>
import Phone from '@/classes/Phone'
import Editor from './PhoneEditDialog.vue'
import * as mutations from '@/store/MutationTypes'
import OptIn from '@/components/Forms/OptInSmsWithNumber'

export default {
  props: ['phonelist'],
  data () {
    return {
      selectedPhone: null,
      dialog: false,
      deleting: false
    }
  },
  computed: {
    list () {
      return this.phonelist.map(p => new Phone(p))
    },
    mode () {
      return this.selectedPhone.id > 0 ? 'add' : 'edit'
    }
  },
  methods: {
    deletePhone (phone) {
      this.dialog = true
      this.selectedPhone = new Phone(phone)
    },
    selectPhone (phone) {
      this.$emit('verify', { type: 'phone', number: phone.number.replace(/\D/g, '') })
    },
    onCancel () {
      this.selectedPhone = null
      this.dialog = false
    },
    addPhone () {
      this.selectedPhone = new Phone()
    },
    doDelete () {
      if (this.selectedPhone.id === 0) {
        this.cancel()
        return
      }
      this.deleting = true
      this.$VBL.user.deletePhone(this.selectedPhone.id)
        .then((response) => {
          this.$store.commit(mutations.SET_USER, response.data)
          this.onCancel()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.deleting = false
        })
    }
  },
  components: {
    'phone-editor': Editor,
    OptIn
  }
}
</script>

<style>

</style>
