<template>
    <v-dialog
      v-model="dialog"
      max-width="400"
      persistent
    >
      <v-card v-if="editablePhone">
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title v-if="mode === 'edit'">{{editablePhone.number | usPhone}}</v-toolbar-title>
          <v-toolbar-title v-else>New Phone</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            fab
            small
            @click.native="cancel"
            color="color2 color2Text--text"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <v-row>
            <v-col>
              <v-text-field
                v-if="mode === 'add'"
                label="Number"
                v-model="editablePhone.number"
                v-mask="'(###) ###-####'"
                color="color3"
                @blur="$v.editablePhone.number.$touch()"
                :rules="$v.editablePhone.number.$dirty ? [
                  () => $v.editablePhone.number.required || 'A phone number is required',
                  () => $v.editablePhone.number.ValidUSPhone || 'A valid phone number is required'
                ] : []"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-list two-line>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox color="success" v-model="editablePhone.isPrimary" :disabled="!canEditPrimary"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content @click.stop="editablePhone.isPrimary = !editablePhone.isPrimary">
                <v-list-item-title>Primary</v-list-item-title>
                <v-list-item-subtitle>Use this as your primary number</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox color="success" v-model="editablePhone.isPublic"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content @click.stop="editablePhone.isPublic = !editablePhone.isPublic">
                <v-list-item-title>Public</v-list-item-title>
                <v-list-item-subtitle>Visible to the public</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action>
                <v-checkbox color="success" v-model="editablePhone.isSMS"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content @click.stop="editablePhone.isSMS = !editablePhone.isSMS">
                <v-list-item-title>SMS Enabled</v-list-item-title>
                <v-list-item-subtitle>Phone can receive text messages</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row class="text-center">
            <v-col>
              <v-btn
                :disabled="!saveable"
                @click="save"
                :loading="saving"
                fab
                color="success white--text"
                class="pl-0"
              >
                <v-icon>fas fa-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-title>
      </v-card>
    </v-dialog>
</template>

<script>
import Phone from '@/classes/Phone'
import vbl from '@/VBL/endpoints'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import * as mutations from '@/store/MutationTypes'
import { mask } from 'vue-the-mask'
import ValidUSPhone from '@/helpers/ValidUSPhone'

export default {
  directives: { mask },
  props: ['phone', 'listCount'],
  mixins: [validationMixin],
  validations: {
    editablePhone: {
      number: { required, ValidUSPhone }
    }
  },
  data () {
    return {
      dialog: false,
      editablePhone: null,
      saving: false
    }
  },
  computed: {
    saveable () {
      return !this.saving && this.mode === 'add' ? !this.$v.$invalid : this.dirty
    },
    dirty () {
      return JSON.stringify(this.phone) !== JSON.stringify(this.editablePhone)
    },
    mode () {
      return this.editablePhone.id === 0 ? 'add' : 'edit'
    },
    canEditPrimary () {
      return this.mode === 'add' ? this.listCount > 0 : this.listCount > 1
    }
  },
  methods: {
    cancel () {
      this.dialog = false
      this.$v.$reset()
      this.$emit('cancel')
    },
    save () {
      if (!this.saveable) return
      this.saving = true
      this.axios.post(vbl.user.phone, this.editablePhone)
        .then((response) => {
          console.log(response)
          this.$store.commit(mutations.SET_USER, response.data)
          this.saving = false
          this.dialog = false
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  watch: {
    phone (newVal, oldVal) {
      if (newVal) {
        this.editablePhone = new Phone(newVal)
        this.dialog = true
        this.$v.editablePhone.number.$reset()
      }
    }
  }
}
</script>

<style>

</style>
