<template>
  <v-list two-line>
    <v-list-item v-for="(email,i) in list" :key="i">
      <v-list-item-action>
        <v-icon color="color1" v-if="i === 0">
          fas fa-envelope
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{email.address}}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{email.propsString}}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action @click="selectEmail(email)">
        <v-btn small text icon ripple color="color3" v-if="!email.isVerified">
          <v-icon>fas fa-cog</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action @click="deleteEmail(email)">
        <v-btn small text icon ripple color="error">
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="emailList.length == 0">
      <v-list-item-action>
        <v-icon color="color1">
          fas fa-envelope
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          No email address on file
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon color="color3">fas fa-plus-circle</v-icon>
      </v-list-item-action>
    </v-list-item>

    <email-editor :email="!dialog && selectedEmail" :listCount="emailList.length"></email-editor>
    <vbl-confirm-dialog
      :ask="dialog"
      @confirm="doDelete"
      @deny="onCancel"
      :loading="deleting"
    ></vbl-confirm-dialog>

  </v-list>
</template>

<script>
import Email from '../../classes/Email'
import Editor from './EmailEditDialog.vue'
import * as mutations from '@/store/MutationTypes'

export default {
  props: ['emailList'],
  data () {
    return {
      selectedEmail: null,
      dialog: false,
      deleting: false
    }
  },
  computed: {
    list () {
      return this.emailList.map(p => new Email(p))
    }
  },
  methods: {
    deleteEmail (email) {
      this.dialog = true
      this.selectedEmail = new Email(email)
    },
    selectEmail (email) {
      // this.selectedEmail = new Email(email)
      this.$emit('verify', { type: 'email', address: email.address })
    },
    onCancel () {
      this.selectedEmail = null
      this.dialog = false
    },
    add () {
      this.selectedEmail = new Email()
    },
    doDelete () {
      if (this.selectedEmail.id === 0) {
        this.cancel()
        return
      }
      this.deleting = true
      this.$VBL.user.deleteEmail(this.selectedEmail.id)
        .then((response) => {
          this.$store.commit(mutations.SET_USER, response.data)
          this.onCancel()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.deleting = false
        })
    }
  },
  components: {
    'email-editor': Editor
  }
}
</script>

<style>

</style>
